<div class="row row-no-padding row-col-separator-xl">
    <div class="col pr-0">
        <div class="card card-custom bgi-no-repeat card-stretch new-subscription-statistics">
          
                <!--<a href="javascript:;" class="text-left font-weight-bolder text-dark font-size-h6 text-hover-state-dark d-block">
                    Total Quiz
                </a>-->
                <div class="card">
                <div class="pt-9 pl-6 pr-6">
                    <img class="float-left" [src]="card1image">
                    <div class="text-muted span1 text-right float-right">
                        Total Quiz
                        <br>
                       <div class="text-danger "> {{count}} </div>
                    </div>
                </div>
                    </div>
              <!--  <div class="progress progress-xs bg-primary-o-60">
                    <div class="progress-bar bg-primary" role="progressbar" style="width: 76%;" aria-valuenow="76"
                         aria-valuemin="0" aria-valuemax="100"></div>
                </div>-->
                <!--<div class="d-flex align-items-center justify-content-between mb-2">
                    <span class="text-muted mr-2 font-size-sm font-weight-bold">
                        Change
                    </span>
                    <span class="text-muted font-size-sm font-weight-bold">
                        <span class="counterup">{{dashboardTopStats.totalProfitChange}}</span>%
                    </span>
                </div>-->
            
        </div>
    </div>

    <div class="col pr-0">
        <div class="card card-custom bgi-no-repeat card-stretch new-subscription-statistics">
         
                <!--<a href="javascript:;"
                   class="text-left font-weight-bolder text-dark font-size-h6 text-hover-state-dark d-block">
                    New Feedbacks
                </a>-->
              
                <div class="pt-9 pl-6 pr-6">
                     <img class="float-left" [src]="card2image" width="30">
                     <div class="text-muted span1 text-right float-right">
                         Quiz Allocated
                         <br>
                        <div class="text-primary"> {{Allocatedcount}}</div>
                     </div>
                   <!-- <span class="text-right float-right text-primary">
                        {{Allocatedcount}}
                    </span>-->
                </div>
                <!--<div class="progress progress-xs bg-info-o-60">
                    <div class="progress-bar bg-info" role="progressbar" style="width: 85%;" aria-valuenow="85"
                         aria-valuemin="0" aria-valuemax="100"></div>
                </div>-->
               <!-- <div class="d-flex align-items-center justify-content-between mb-2">
                    <span class="text-muted mr-2 font-size-sm font-weight-bold">
                        Change
                    </span>
                    <span class="text-muted font-size-sm font-weight-bold">
                        <span class="counterup">{{dashboardTopStats.newFeedbacks}}</span>%
                    </span>
                </div>-->
          
        </div>
    </div>

    <div class="col pr-0">
        <div class="card card-custom bgi-no-repeat card-stretch new-subscription-statistics">
            
                <!--<a href="javascript:;"
                   class="text-left font-weight-bolder text-dark font-size-h6 text-hover-state-dark d-block">
                    New Orders
                </a>-->
              <div class="card">
                <div class="pt-9 pl-6 pr-6">
                     <img class="float-left card3image" [src]="card3image" width="30">
                     <div class="text-muted span1 text-right float-right">
                         Quiz Completed
                         <br>
                         <div class="text-success">{{Completedcount}}</div>
                     </div>
                    <!--<span class="text-right float-right text-success mr-2">
                        {{Completedcount}}
                    </span>-->
                </div>
                  </div>
               <!-- <div class="progress progress-xs bg-danger-o-60">
                    <div class="progress-bar bg-danger" role="progressbar" style="width: 45%;" aria-valuenow="45"
                         aria-valuemin="0" aria-valuemax="100"></div>
                </div>-->
               <!-- <div class="d-flex align-items-center justify-content-between mb-2">
                    <span class="text-muted mr-2 font-size-sm font-weight-bold">
                        Change
                    </span>
                    <span class="text-muted font-size-sm font-weight-bold">
                        <span class="counterup">{{dashboardTopStats.newOrdersChange}}</span>%
                    </span>
                </div>-->
           
        </div>
    </div>

    <div class="col pr-0">
        <div class="card card-custom bgi-no-repeat card-stretch new-subscription-statistics">
         
               <!-- <a href="javascript:;"
                   class="text-left font-weight-bolder text-dark font-size-h6 text-hover-state-dark d-block">
                    New Users
                </a>-->
               <div class="card">
                <div class="pt-9 pl-6 pr-6">
                     <img class="float-left" [src]="card4image" width="30">
                     <div class="text-muted span1 text-right float-right">
                         Pending Quiz
                         <br>
                        <div class="text-warning"> {{draftQuizCount}}</div>
                     </div>
                   <!-- <span class="text-right float-right text-warning mr-2">
                        {{draftQuizCount}}
                    </span>-->
                </div>
                   </div>
              <!--  <div class="progress progress-xs bg-success-o-60">
                    <div class="progress-bar bg-success" role="progressbar" style="width: 57%;" aria-valuenow="57"
                         aria-valuemin="0" aria-valuemax="100"></div>
                </div>-->
                <!--<div class="d-flex align-items-center justify-content-between mb-2">
                    <span class="text-muted mr-2 font-size-sm font-weight-bold">
                        Change
                    </span>
                    <span class="text-muted font-size-sm font-weight-bold">
                        <span class="counterup">{{dashboardTopStats.newUsersChange}}</span>%
                    </span>
                </div>-->
           
        </div>
    </div>
    <div class="col pr-0">
        <div class="card card-custom bgi-no-repeat card-stretch new-subscription-statistics">
            
                <!--<a href="javascript:;"
                   class="text-left font-weight-bolder text-dark font-size-h6 text-hover-state-dark d-block">
                    New Users
                </a>-->
               <div class="card">
                <div class="pt-9 pl-6 pr-6">
                     <img class="float-left" [src]="card5image" width="30">
                    <div class="text-muted span1 text-right float-right">
                    Total Pass
                    <br>
                    <div class="text-info"> {{passCount}} </div>
                    </div>
                  <!--  <span class="text-right float-right text-info">
                        0
                    </span>-->
                </div>
                   </div>
               
               <!-- <div>
                     <img class="float-left pt-3" [src]="card5image" width="35">
                    <span class="text-muted span1 text-right float-right">Total Pass</span>
                    <span class="text-right float-right text-info">
                       
                    </span>
                </div>-->
               <!-- <div class="progress progress-xs bg-success-o-60">
                    <div class="progress-bar bg-success" role="progressbar" style="width: 57%;" aria-valuenow="57"
                         aria-valuemin="0" aria-valuemax="100"></div>
                </div>-->
               <!-- <div class="d-flex align-items-center justify-content-between mb-2">
                    <span class="text-muted mr-2 font-size-sm font-weight-bold">
                        Change
                    </span>
                    <span class="text-muted font-size-sm font-weight-bold">
                        <span class="counterup">{{dashboardTopStats.newUsersChange}}</span>%
                    </span>
                </div>-->
           
        </div>
    </div>
    <div class="col pr-0">
        <div class="card card-custom bgi-no-repeat card-stretch new-subscription-statistics">
          
               <!-- <a href="javascript:;"
                   class="text-left font-weight-bolder text-dark font-size-h6 text-hover-state-dark d-block">
                    New Users
                </a>-->
               <div class="card">
                <div class="p-10">
                     <img class="float-left card6img" [src]="card6image" width="30">
                    <div class="text-muted span1  text-right float-right">
                    Total Fail
                        <br>
                        <div class="text-pink"> {{failCount}} </div>
                    
                    </div>
                   <!-- <span class="text-right float-right text-pink">
                        0
                    </span>-->
                </div>
                   </div>
               
               <!-- <div>
                     <img class="float-left pt-3" [src]="card6image" width="35">
                    <span class="text-muted span1  text-right float-right">Total Fail</span>
                    <span class="text-right float-right text-pink">
                        {{failCount}}
                    </span>
                </div>-->
              <!--  <div class="progress progress-xs bg-success-o-60">
                    <div class="progress-bar bg-success" role="progressbar" style="width: 57%;" aria-valuenow="57"
                         aria-valuemin="0" aria-valuemax="100"></div>
                </div>-->
                <!--<div class="d-flex align-items-center justify-content-between mb-2">
                    <span class="text-muted mr-2 font-size-sm font-weight-bold">
                        Change
                    </span>
                    <span class="text-muted font-size-sm font-weight-bold">
                        <span class="counterup">{{dashboardTopStats.newUsersChange}}</span>%
                    </span>
                </div>-->
           
        </div>
    </div>

</div>
