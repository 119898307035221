import { Component, OnInit, Injector } from '@angular/core';
import { TenantDashboardServiceProxy, QuizServiceProxy, GetQuizForViewDto, QuizAllocationsServiceProxy, QuizBuilderDto, ExamServiceProxy } from '@shared/service-proxies/service-proxies';
import { DashboardChartBase } from '../dashboard-chart-base';
import { WidgetComponentBase } from '../widget-component-base';
import { Router } from '@angular/router';
import { LazyLoadEvent } from 'primeng/api';
import { AppComponentBase } from '../../../../../../shared/common/app-component-base';
import { count } from 'console';
import { AppConsts } from '../../../../../../shared/AppConsts';


class DashboardTopStats extends DashboardChartBase {

    totalProfit = 0; totalProfitCounter = 0;
    newFeedbacks = 0; newFeedbacksCounter = 0;
    newOrders = 0; newOrdersCounter = 0;
    newUsers = 0; newUsersCounter = 0;

    totalProfitChange = 76; totalProfitChangeCounter = 0;
    newFeedbacksChange = 85; newFeedbacksChangeCounter = 0;
    newOrdersChange = 45; newOrdersChangeCounter = 0;
    newUsersChange = 57; newUsersChangeCounter = 0;

    init(totalProfit, newFeedbacks, newOrders, newUsers) {
        this.totalProfit = totalProfit;
        this.newFeedbacks = newFeedbacks;
        this.newOrders = newOrders;
        this.newUsers = newUsers;
        this.hideLoading();
    }
}

@Component({
    selector: 'app-widget-top-stats',
    templateUrl: './widget-top-stats.component.html',
    styleUrls: ['./widget-top-stats.component.css']
})
export class WidgetTopStatsComponent extends AppComponentBase implements OnInit {
    filterText = '';
    displayNameFilter = '';
    descriptionFilter = '';
    surveyJSONFilter = '';
    isActiveFilter = -1;
    quizBuilderExamTitleFilter = '';
    SelectedQuiz: GetQuizForViewDto[] = [];
    AllQuiz: GetQuizForViewDto[] = [];
    quizInDraft: QuizBuilderDto[] = [];
    count: number = 0;
    advancedFiltersAreShown = false;
    filterText1 = '';
    quizDisplayNameFilter = '';
    QuizTitleNameFilter = '';
    userNameFilter = '';
    Completedcount: number = 0;
    Allocatedcount: number = 0;
    draftQuizCount: any;
    passCount: any;
    failCount: any;
    card1image = AppConsts.appBaseUrl + '/assets/cards/card1.svg';
    card2image = AppConsts.appBaseUrl + '/assets/cards/card2.svg';
    card3image = AppConsts.appBaseUrl + '/assets/cards/card3.svg';
    card4image = AppConsts.appBaseUrl + '/assets/cards/card4.svg';
    card5image = AppConsts.appBaseUrl + '/assets/cards/card5.svg';
    card6image = AppConsts.appBaseUrl + '/assets/cards/card6.svg';

    dashboardTopStats: DashboardTopStats;

    constructor(injector: Injector,
        private _tenantDashboardServiceProxy: TenantDashboardServiceProxy,

        private router: Router,
        private _quizServiceProxy: QuizServiceProxy,
        private _quizAllocationsServiceProxy: QuizAllocationsServiceProxy,
        private _quizRepo: QuizServiceProxy,
        private _examRepo: ExamServiceProxy
    ) {
        super(injector);
        this.dashboardTopStats = new DashboardTopStats();
    }

    ngOnInit() {

        this.getQuiz();
        this.getQuizAllocations();
        this.getInDraftQuiz();
        this.getPassFailCount();
    }

    getQuiz(event?: LazyLoadEvent) {


        this.primengTableHelper.showLoadingIndicator();

        this._quizServiceProxy.getAll(
            this.filterText,
            this.displayNameFilter,
            this.descriptionFilter,
            this.surveyJSONFilter,
            this.isActiveFilter,
            this.quizBuilderExamTitleFilter,
            undefined,
            undefined,
            50
        ).subscribe(result => {

            this.count = result.totalCount;
            console.log(this.count);
            this.primengTableHelper.records = result.items;
            this.AllQuiz = result.items;
            this.primengTableHelper.hideLoadingIndicator();

        });
    }

    getQuizAllocations(event?: LazyLoadEvent) {


        this.primengTableHelper.showLoadingIndicator();

        this._quizAllocationsServiceProxy.getAll(
            this.filterText1,
            this.quizDisplayNameFilter,
            this.userNameFilter,
            undefined,
            undefined,
            50
        ).subscribe(result => {
            this.primengTableHelper.totalRecordsCount = result.totalCount;
            result.items.forEach(x => {
                if (x.quizAllocation.quizResult) {
                    x.status = "Completed "
                    this.Completedcount++;
                }
                else {
                    x.status = "Allocated"
                    this.Allocatedcount++;
                }
            });
            console.log(this.Completedcount);
            console.log(this.Allocatedcount);
            this.primengTableHelper.records = result.items;
            this.primengTableHelper.hideLoadingIndicator();

        });
    }

    getInDraftQuiz() {

        this._quizRepo.getInDraftQuiz(
            this.filterText,
            this.quizDisplayNameFilter,
            this.QuizTitleNameFilter)
            .subscribe(
                x => {
                    this.quizInDraft = x;
                    this.draftQuizCount = this.quizInDraft.length;

                });
    }


    loadTopStatsData() {
        this._tenantDashboardServiceProxy.getTopStats().subscribe((data) => {
            this.dashboardTopStats.init(data.totalProfit, data.newFeedbacks, data.newOrders, data.newUsers);
        });
    }

    getPassFailCount() {
        this._examRepo.getPassFailCount().subscribe(x => {
            this.failCount = x.failCount;
            this.passCount = x.passCount;           
        });
    }


}
