<!-- BEGIN: Aside Menu -->
<div *ngIf="appSession.tenant && appSession.tenant.logoId" [class]="'aside-menu-wrapper aside-menu-' + currentTheme.baseSettings.menu.asideSkin" id="kt_aside_menu_wrapper">
    <div #asideMenu ktMenu [options]="menuOptions" [perfectScrollbar]="{wheelPropagation: false}"
         [ngStyle]="{'max-height': '90vh', 'position': 'relative'}" id="kt_aside_menu" class="aside-menu scroll"
         [attr.data-menu-vertical]="1" [attr.data-menu-scroll]="ui.getIsMenuScrollable() ? 1 : 0"
         [attr.data-menu-dropdown]="ui.isSubmenuToggleDropdown() ? 1 : 0" (mouseenter)="mouseEnter($event)"
         (mouseleave)="mouseLeave($event)" [class]="ui.getLeftAsideClass()">
        <div class="col-12">
            <button class="brand-toggle btn btn-sm px-0" id="kt_aside_toggle"
                    *ngIf="currentTheme.baseSettings.menu.allowAsideMinimizing" (click)="toggleLeftAside()">
                <span class="svg-icon svg-icon svg-icon-xl">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px"
                         height="24px" viewBox="0 0 24 24" version="1.1">
                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <polygon points="0 0 24 0 24 24 0 24"></polygon>
                            <path d="M5.29288961,6.70710318 C4.90236532,6.31657888 4.90236532,5.68341391 5.29288961,5.29288961 C5.68341391,4.90236532 6.31657888,4.90236532 6.70710318,5.29288961 L12.7071032,11.2928896 C13.0856821,11.6714686 13.0989277,12.281055 12.7371505,12.675721 L7.23715054,18.675721 C6.86395813,19.08284 6.23139076,19.1103429 5.82427177,18.7371505 C5.41715278,18.3639581 5.38964985,17.7313908 5.76284226,17.3242718 L10.6158586,12.0300721 L5.29288961,6.70710318 Z"
                                  fill="#000000" fill-rule="nonzero"
                                  transform="translate(8.999997, 11.999999) scale(-1, 1) translate(-8.999997, -11.999999) ">
                            </path>
                            <path d="M10.7071009,15.7071068 C10.3165766,16.0976311 9.68341162,16.0976311 9.29288733,15.7071068 C8.90236304,15.3165825 8.90236304,14.6834175 9.29288733,14.2928932 L15.2928873,8.29289322 C15.6714663,7.91431428 16.2810527,7.90106866 16.6757187,8.26284586 L22.6757187,13.7628459 C23.0828377,14.1360383 23.1103407,14.7686056 22.7371482,15.1757246 C22.3639558,15.5828436 21.7313885,15.6103465 21.3242695,15.2371541 L16.0300699,10.3841378 L10.7071009,15.7071068 Z"
                                  fill="#000000" fill-rule="nonzero" opacity="0.3"
                                  transform="translate(15.999997, 11.999999) scale(-1, 1) rotate(-270.000000) translate(-15.999997, -11.999999) ">
                            </path>
                        </g>
                    </svg>
                </span>
            </button>
        </div>
        <div class="mt-2" *ngIf="'Pages.Tenant.CreateQuiz' | permission">
            <button (click)="gotoCreateQuiz()" style="height:44px; width:266px; background-color:white; border:2.5px solid #e6e6e83d;border-left-width: 6px;border-left-color: #D7262C">
                <div class="row"  *ngIf="!(show)">
                    <div class="pl-9">
                        <img [src]="plusicon" width="10">
                        <span class="pl-6" style="color:gray">
                            <b>
                                Create Quiz
                            </b>
                        </span>
                    </div>
                </div>
                <div class="row"  *ngIf="show">
                    <div class="pl-9">
                        <img [src]="plusicon" width="10">
                        <span class="pl-6" style="color:gray">
                           <!-- <b>
                                Create Quiz
                            </b>-->
                        </span>
                    </div>
                </div>
            </button>
        </div>
        <ul class="menu-nav">
            <!-- <li  class="menu-item mb-5" (click)="gotoCreateQuiz()"><img [src]="plusicon"> &nbsp; Create Quiz</li>-->
            <li class="menu-item mb-5" aria-haspopup="true"
                data-ktmenu-submenu-toggle="hover" *ngIf="currentTheme.baseSettings.menu.searchActive">
                <menu-search-bar></menu-search-bar>
            </li>
            <ng-container [ngTemplateOutlet]="menuListTemplate"></ng-container>
        </ul>
    </div>
</div>
<!-- END: Aside Menu -->
<!-- BEGIN: Aside Menu -->
<div *ngIf="!appSession.tenant || !appSession.tenant.logoId" [class]="'aside-menu-wrapper aside-menu-' + currentTheme.baseSettings.menu.asideSkin" id="kt_aside_menu_wrapper">
    <div #asideMenu ktMenu [options]="menuOptions" [perfectScrollbar]="{wheelPropagation: false}"
         [ngStyle]="{'max-height': '90vh', 'position': 'relative'}" id="kt_aside_menu" class="aside-menu scroll"
         [attr.data-menu-vertical]="1" [attr.data-menu-scroll]="ui.getIsMenuScrollable() ? 1 : 0"
         [attr.data-menu-dropdown]="ui.isSubmenuToggleDropdown() ? 1 : 0" (mouseenter)="mouseEnter($event)"
         (mouseleave)="mouseLeave($event)" [class]="ui.getLeftAsideClass()">

        <div class="col-12">
            <button class="brand-toggle btn btn-sm px-0" id="kt_aside_toggle"
                    *ngIf="currentTheme.baseSettings.menu.allowAsideMinimizing" (click)="toggleLeftAside()">
                <span class="svg-icon svg-icon svg-icon-xl">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px"
                         height="24px" viewBox="0 0 24 24" version="1.1">
                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <polygon points="0 0 24 0 24 24 0 24"></polygon>
                            <path d="M5.29288961,6.70710318 C4.90236532,6.31657888 4.90236532,5.68341391 5.29288961,5.29288961 C5.68341391,4.90236532 6.31657888,4.90236532 6.70710318,5.29288961 L12.7071032,11.2928896 C13.0856821,11.6714686 13.0989277,12.281055 12.7371505,12.675721 L7.23715054,18.675721 C6.86395813,19.08284 6.23139076,19.1103429 5.82427177,18.7371505 C5.41715278,18.3639581 5.38964985,17.7313908 5.76284226,17.3242718 L10.6158586,12.0300721 L5.29288961,6.70710318 Z"
                                  fill="#000000" fill-rule="nonzero"
                                  transform="translate(8.999997, 11.999999) scale(-1, 1) translate(-8.999997, -11.999999) ">
                            </path>
                            <path d="M10.7071009,15.7071068 C10.3165766,16.0976311 9.68341162,16.0976311 9.29288733,15.7071068 C8.90236304,15.3165825 8.90236304,14.6834175 9.29288733,14.2928932 L15.2928873,8.29289322 C15.6714663,7.91431428 16.2810527,7.90106866 16.6757187,8.26284586 L22.6757187,13.7628459 C23.0828377,14.1360383 23.1103407,14.7686056 22.7371482,15.1757246 C22.3639558,15.5828436 21.7313885,15.6103465 21.3242695,15.2371541 L16.0300699,10.3841378 L10.7071009,15.7071068 Z"
                                  fill="#000000" fill-rule="nonzero" opacity="0.3"
                                  transform="translate(15.999997, 11.999999) scale(-1, 1) rotate(-270.000000) translate(-15.999997, -11.999999) ">
                            </path>
                        </g>
                    </svg>
                </span>
            </button>
        </div>

        <div class="mt-2" *ngIf="'Pages.Tenant.CreateQuiz' | permission">
            <button (click)="gotoCreateQuiz()" style="height:44px; width:266px; background-color:white; border:2.5px solid #e6e6e83d;border-left-width: 6px;border-left-color: #D7262C">
                <div class="row"  *ngIf="!(show)">
                    <div class="pl-9">
                        <img [src]="plusicon" width="10">
                        <span class="pl-6" style="color:gray">
                            <b>
                                Create Quiz
                            </b>
                        </span>
                    </div>
                </div>
                <div class="row" *ngIf="show">
                    <div class="pl-9">
                        <img [src]="plusicon" width="10">
                        <span class="pl-6" style="color:gray">
                           <!-- <b>
                                Create Quiz
                            </b>-->
                        </span>
                    </div>
                </div>
            </button>
        </div>
        <ul class="menu-nav">
            <!-- <li  class="menu-item mb-5" (click)="gotoCreateQuiz()"><img [src]="plusicon"> &nbsp; Create Quiz</li>-->
            <li class="menu-item mb-5" aria-haspopup="true"
                data-ktmenu-submenu-toggle="hover" *ngIf="currentTheme.baseSettings.menu.searchActive">
                <menu-search-bar></menu-search-bar>
            </li>
            <ng-container [ngTemplateOutlet]="menuListTemplate"></ng-container>
        </ul>
    </div>
</div>

<!-- </div> -->

<ng-template #menuListTemplate>
    <ng-container *ngFor="let child of menu.items">
        <ng-container *ngIf="showMenuItem(child)" [ngTemplateOutlet]="mMenuItem"
                      [ngTemplateOutletContext]="{ item: child, parentItem: null }"></ng-container>
    </ng-container>
</ng-template>

<ng-template #mMenuItem let-item="item" let-parentItem="parentItem">
    <li *ngIf="showMenuItem(item)" [attr.aria-haspopup]="true"
        [attr.data-menu-toggle]="getItemAttrSubmenuToggle(item)"
        [attr.data-ktmenu-submenu-mode]="item.mode"
        [attr.data-ktmenu-dropdown-toggle-class]="item['dropdown-toggle-class']" [ngClass]="getItemCssClasses(item)"
        data-placement="right">

        <!-- if menu item hasn't submenu -->
        <a *ngIf="!item.items.length && !item.external" [queryParams]="item.parameters" [routerLink]="item.route"
           class="menu-link">
            <ng-container [ngTemplateOutlet]="mMenuItemText"
                          [ngTemplateOutletContext]="{ item: item, parentItem: parentItem }"></ng-container>
        </a>
        <a *ngIf="!item.items.length && item.external" [attr.href]="item.route" target="_blank"
           class="menu-link menu-toggle">
            <ng-container [ngTemplateOutlet]="mMenuItemText"
                          [ngTemplateOutletContext]="{ item: item, parentItem: parentItem }"></ng-container>
        </a>

        <!-- if menu item has submenu child  -->
        <a href="javascript:;" *ngIf="item.items.length" class="menu-link menu-toggle">
            <ng-container [ngTemplateOutlet]="mMenuItemText"
                          [ngTemplateOutletContext]="{ item: item, parentItem: parentItem }"></ng-container>
        </a>

        <!-- if menu item has submenu child then recursively call new menu item component -->
        <div *ngIf="item.items.length" class="menu-submenu" [style]="ui.getLeftAsideSubMenuStyles()">
            <span class="menu-arrow"></span>
            <ul class="menu-subnav">
                <ng-container *ngFor="let child of item.items">
                    <ng-container [ngTemplateOutlet]="mMenuItem"
                                  [ngTemplateOutletContext]="{ item: child, parentItem: item }"></ng-container>
                </ng-container>
            </ul>
        </div>
    </li>
</ng-template>

<ng-template #mMenuItemText let-item="item" let-parentItem="parentItem">
    <span class="menu-item-here"></span>
    <!-- if menu item has icon -->
    <span class="menu-icon" *ngIf="item.icon">
        <i class="menu-link-icon" [ngClass]="item.icon"></i>
    </span>

    <ng-container *ngIf="!item.badge; else mMenuLinkBadge">
        <!-- menu item title text -->
        <span class="menu-text">
            {{item.name | localize}}
        </span>
    </ng-container>

    <ng-template #mMenuLinkBadge>
        <!-- menu item with badge -->
        <span class="menu-link-title">
            <span class="menu-link-wrap">
                <span class="menu-text">
                    {{item.name | localize}}
                </span>
                <span class="menu-link-badge">
                    <span class="label label-primary" [ngClass]="item.badge.type">{{item.badge.value}}</span>
                </span>
            </span>
        </span>
    </ng-template>

    <!-- if menu item has submenu child then put arrow icon -->
    <span *ngIf="item.items.length" class="menu-arrow"></span>
</ng-template>
<!-- END: Left Aside -->